.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  width: 90%;
  padding: 20px 30px;
  position: relative;
  margin: 0 auto;
  max-width: 600px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: auto;
}
.secondContainer {
  padding: 20px 20px;
  width: 100%;
  height: 100%;
}

.closeButton {
  position: relative;
  /* top: 20px; */
  /* right: 20px; */
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.formClosing {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  margin: 0 0 20px;
  font-size: 18px;
}

.formGroup {
  margin-bottom: 0;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input,
textarea {
  width: 100%;
  /* border-radius: 4px; */
}

.smallText {
  font-size: 10px;
  text-align: center;
}

textarea {
  resize: none;
}

.submitButton {
  margin: 0 0;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}
.statusMessage {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

.successMessage {
  color: #155724; /* Dark green text */
  font-weight: bold;
  animation: fadeIn 0.5s ease-in-out; /* Optional animation */
}
.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
}

.checkbox {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox:checked {
  accent-color: #007bff; /* Adjust to your primary theme color */
}

.row {
  display: flex;
  gap: 1rem; /* Adds space between the fields */
}

.row .field {
  flex: 1; /* Ensures both fields take equal width */
}

.error {
  font-size: 13px;
  font-weight: 600;
  color: red;
}
/* Optional: Add a fade-in effect for better user experience */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .modalContent {
    padding: 20px;
  }
  .secondContainer {
    padding: 20px 0;
    width: 100%;
  }
}
